import { render, staticRenderFns } from "./ListCard.vue?vue&type=template&id=2db34bd8&scoped=true&"
import script from "./ListCard.vue?vue&type=script&lang=js&"
export * from "./ListCard.vue?vue&type=script&lang=js&"
import style0 from "./ListCard.vue?vue&type=style&index=0&id=2db34bd8&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2db34bd8",
  null
  
)

export default component.exports