var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard",style:({
        width: _vm.configuration.parentConfig.width + 'vw',
        left: _vm.configuration.parentConfig.left + 'vw'
    })},[_vm._l((_vm.dashItemsFilterByRoles),function(dashboardItem,index){return _c('DashboardItem',{key:dashboardItem.name,staticClass:"dashboard__item",class:{'dashboard__item_last': index === _vm.dashItemsFilterByRoles.length - 1},style:({
            width: _vm.configuration.itemConfig.widthCoef + '%',
            height: _vm.configuration.itemConfig.height + 'px',
            transform:
                'translate(' + _vm.configuration.parentConfig.width / 100 * _vm.configuration.itemConfig.left(index) + 'vw,' + _vm.configuration.itemConfig.top(index) + 'px)'
        }),attrs:{"dash-item":dashboardItem},nativeOn:{"click":function($event){return _vm.selectSection(dashboardItem.name)}}})}),_c('KeepAlive',[_c('Transition',{attrs:{"name":_vm.configuration.routerTransitionName}},[_c('RouterView',{key:_vm.configuration.keyRouterView})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }